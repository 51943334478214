var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { id: "mentionlist", tag: "section" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "12" } },
            [
              _vm.error
                ? _c(
                    "base-material-alert",
                    { attrs: { dark: "", color: "error", dismissible: "" } },
                    [_vm._v("\n        " + _vm._s(_vm.error) + "\n      ")]
                  )
                : _vm._e(),
              _c(
                "base-material-card",
                {
                  attrs: { color: "success", icon: "mdi-badge-account" },
                  scopedSlots: _vm._u([
                    {
                      key: "after-heading",
                      fn: function() {
                        return [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { right: "", "max-width": "50%" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "div",
                                        _vm._g(
                                          {
                                            staticClass:
                                              "card-title font-weight-light"
                                          },
                                          on
                                        ),
                                        [
                                          _vm._v(
                                            "\n                Отзывы\n                "
                                          ),
                                          _c(
                                            "v-icon",
                                            { staticClass: "help-pointer" },
                                            [
                                              _vm._v(
                                                "\n                  mdi-help-circle-outline\n                "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ])
                            },
                            [_c("span", [_vm._v("Отзывы")])]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c(
                    "v-card-text",
                    { staticClass: "pt-0" },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pt-0 pb-0 pl-0",
                              attrs: { cols: "12", md: "auto" }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "ma-1",
                                  attrs: {
                                    color: "secondary",
                                    rounded: "",
                                    target: "_blank"
                                  },
                                  on: { click: _vm.createMention }
                                },
                                [
                                  _c("v-icon", { attrs: { left: "" } }, [
                                    _vm._v(
                                      "\n                  mdi-plus\n                "
                                    )
                                  ]),
                                  _vm._v(
                                    "\n                Добавить\n              "
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("v-col", { staticClass: "pt-0 pb-0" }),
                          _c(
                            "v-col",
                            {
                              staticClass: "pt-0 pb-0",
                              attrs: { cols: "12", md: "auto" }
                            },
                            [
                              _c("v-switch", {
                                staticClass: "mt-0 mb-0",
                                attrs: { label: _vm.onlyArchivedLabel },
                                model: {
                                  value: _vm.onlyArchived,
                                  callback: function($$v) {
                                    _vm.onlyArchived = $$v
                                  },
                                  expression: "onlyArchived"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-data-table", {
                    attrs: {
                      loading: _vm.loading,
                      "loading-text": "Загрузка...",
                      headers: _vm.headers,
                      items: _vm.items,
                      search: _vm.search,
                      "items-per-page": _vm.itemsPerPage,
                      page: _vm.page,
                      "sort-by": ["id"],
                      "sort-desc": [true]
                    },
                    on: {
                      "update:itemsPerPage": function($event) {
                        _vm.itemsPerPage = $event
                      },
                      "update:items-per-page": function($event) {
                        _vm.itemsPerPage = $event
                      },
                      "update:page": function($event) {
                        _vm.page = $event
                      }
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "body",
                        fn: function(ref) {
                          var items = ref.items
                          return [
                            _c(
                              "tbody",
                              _vm._l(items, function(item) {
                                return _c(
                                  "tr",
                                  {
                                    key: item.id,
                                    class: { archived: !item.status },
                                    on: {
                                      click: function($event) {
                                        return _vm.singleMention(item)
                                      }
                                    }
                                  },
                                  [
                                    _c("td", [_vm._v(_vm._s(item.id))]),
                                    _c(
                                      "td",
                                      { staticClass: "text-top" },
                                      [
                                        item.photo
                                          ? _c("v-img", {
                                              staticClass: "grey lighten-2",
                                              attrs: {
                                                src: _vm.imageLink(item.id),
                                                "lazy-src": _vm.imageLink(
                                                  item.id
                                                ),
                                                "aspect-ratio": "1.5",
                                                width: "100px"
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "placeholder",
                                                    fn: function() {
                                                      return [
                                                        _c(
                                                          "v-row",
                                                          {
                                                            staticClass:
                                                              "fill-height ma-0",
                                                            attrs: {
                                                              align: "center",
                                                              justify: "center"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-progress-circular",
                                                              {
                                                                attrs: {
                                                                  indeterminate:
                                                                    "",
                                                                  color:
                                                                    "grey lighten-5"
                                                                }
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    },
                                                    proxy: true
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            })
                                          : _vm._e()
                                      ],
                                      1
                                    ),
                                    _c("td", [_vm._v(_vm._s(item.name))]),
                                    _c("td", [_vm._v(_vm._s(item.comment))]),
                                    _c("td", [_vm._v(_vm._s(item.reply))]),
                                    _c("td", [_vm._v(_vm._s(item.created))]),
                                    _c(
                                      "td",
                                      { staticClass: "text-right" },
                                      [
                                        item.status
                                          ? _c(
                                              "v-btn",
                                              {
                                                staticClass: "px-2 ml-1",
                                                attrs: {
                                                  color: "error",
                                                  "min-width": "0",
                                                  title: "Переместить в архив",
                                                  small: "",
                                                  disabled: _vm.loading
                                                },
                                                on: {
                                                  click: function($event) {
                                                    $event.stopPropagation()
                                                    return _vm.confirmArchive(
                                                      item
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { small: "" } },
                                                  [
                                                    _vm._v(
                                                      "\n                      mdi-close\n                    "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        !item.status
                                          ? _c(
                                              "v-btn",
                                              {
                                                staticClass: "px-2 ml-1",
                                                attrs: {
                                                  color: "info",
                                                  "min-width": "0",
                                                  title: "Вернуть из архива",
                                                  small: "",
                                                  disabled: _vm.loading
                                                },
                                                on: {
                                                  click: function($event) {
                                                    $event.stopPropagation()
                                                    return _vm.confirmUnArchive(
                                                      item
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { small: "" } },
                                                  [
                                                    _vm._v(
                                                      "\n                      mdi-restore\n                    "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ]
                                )
                              }),
                              0
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "450" },
          model: {
            value: _vm.confirmArchiveDialog,
            callback: function($$v) {
              _vm.confirmArchiveDialog = $$v
            },
            expression: "confirmArchiveDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v("\n        Переместить отзыв в архив?\n      ")
              ]),
              _c("v-card-text", [
                _vm._v(
                  "\n        После помещения в архив отзыв не будет отображаться на сайте.\n      "
                )
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "darken-1", text: "" },
                      on: {
                        click: function($event) {
                          _vm.confirmArchiveDialog = false
                        }
                      }
                    },
                    [_vm._v("\n          Отмена\n        ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error darken-1", text: "" },
                      on: {
                        click: function($event) {
                          return _vm.archive(_vm.selectedMention)
                        }
                      }
                    },
                    [_vm._v("\n          Переместить в архив\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "450" },
          model: {
            value: _vm.confirmUnArchiveDialog,
            callback: function($$v) {
              _vm.confirmUnArchiveDialog = $$v
            },
            expression: "confirmUnArchiveDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v("\n        Вернуть отзыв из архива?\n      ")
              ]),
              _c("v-card-text", [
                _vm._v(
                  "\n        После возврата отзыва из архива он будет отображаться на сайте.\n      "
                )
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "darken-1", text: "" },
                      on: {
                        click: function($event) {
                          _vm.confirmUnArchiveDialog = false
                        }
                      }
                    },
                    [_vm._v("\n          Отмена\n        ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error darken-1", text: "" },
                      on: {
                        click: function($event) {
                          return _vm.unarchive(_vm.selectedMention)
                        }
                      }
                    },
                    [_vm._v("\n          Вернуть из архива\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
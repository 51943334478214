import axios from 'axios'

export default {
  updateMention (mention) {
    return axios.put('mentions/' + mention.id, mention).then(response => {
      return response.data
    })
  },

  createMention (mention) {
    return axios.post('mentions', mention).then(response => {
      return response.data
    })
  },

  deleteMention (id) {
    return axios.delete('mentions/' + id).then(response => {
      return response.data
    })
  },

  fetchMention (id) {
    return axios.get('mentions/' + id)
      .then(response => {
        return response.data
      })
  },

  fetchMentions () {
    return axios.get('mentions').then(response => {
      return response.data
    })
  },
}
